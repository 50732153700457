.HomeThreeBoxes{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 15vw;
    margin-top: 7vw;
    margin-bottom: 7vw;
}
.HomeBox{
    width: 24.5vw;
    height: 100%;
    box-shadow: 0px 0px 20px 0px #000000bd;
    -webkit-box-shadow: 0px 0px 20px 0px #000000bd;
    -moz-box-shadow: 0px 0px 20px 0px #000000bd;
    border-radius: 5px;
    padding: 1.5vw 1vw;
    padding-top: 2vw;
}
.HomeBox h2{
    text-align: center;
    font-size: 1.5vw;
    line-height: 1.7vw;
    font-weight: 200;
    color: #f2d729;
}
.boxNumber{
    text-align: center;
    font-size: 3.7vw;
    line-height: 4.2vw;
    margin-top: 2vw;
    background-color: #4158D0;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.num1{
    background-color: #00DBDE;
    background-image: linear-gradient(90deg, #00DBDE 0%, #FC00FF 100%);
}
.num2{
    background-color: #FBDA61;
    background-image: linear-gradient(45deg, #FBDA61 0%, #FF5ACD 100%);
}
.num3{
    background-color: #08AEEA;
    background-image: linear-gradient(0deg, #08AEEA 0%, #2AF598 100%);    
}

@media screen and (max-width: 550px){
    .HomeThreeBoxes {
        margin-right: 6vw;
        margin-left: 6vw;
        flex-wrap: wrap;
        gap: 5vw;
    }
    .HomeBox {
        width: 80vw;
        border-radius: 5px;
        padding: 3.5vw 3vw;
        padding-top: 4vw;
    }
    .HomeBox h2 {
        font-size: 3.5vw;
        line-height: 3.7vw;
    }
    .boxNumber {
        font-size: 9.7vw;
        line-height: 11vw;
        /* margin-top: 2.7vw; */
        padding-top: 3vw;
        -webkit-background-clip: text;
        -webkit-text-fill-color: #0000;
    }
}