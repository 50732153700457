.scrollTopButton {
    position: fixed; 
   outline: none;
   left: 92vw;
   bottom: 5.6vw;
   height: 1.8vw;
   font-size: 3.8vw;
   z-index: 1;
   cursor: pointer;
   background: transparent;
   border: none;
}

@media screen and (max-width: 550px){
    .scrollTopButton {
       left: 90vw;
       bottom: 7vw;
       height: 2.8vw;
       font-size: 8vw;
    }
}