
@font-face {
  font-family: "kazuoLight";
  src: url("../fonts/kazuoLight.woff2");
}
@font-face {
  font-family: "kazuoLig";
  src: url("../fonts/kazuoDisplayLight.woff2");
}
@font-face {
  font-family: "kazuoLi";
  src: url("../fonts/kazuo.woff2");
}
@font-face {
  font-family: "kazuoItalic";
  src: url("../fonts/kazuoDisplayLightItalic.woff2");
}
@import url("https://fonts.googleapis.com/css2?family=Arvo&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans&display=swap");

@font-face {
  font-family: "newKazuo";
  src: url("../fonts/newKazuo.woff2");
}

@font-face {
  font-family: "telegraf200";
  src: url("../fonts/Telegraf200.otf");
}

@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600&display=swap');

.home__div1 {
  position: relative;
  top: 30vw;
  left: 5vw;
  right: 5vw;
}
.home__div4 {
  display: flex;
}
.home__div5 {
  margin-left: 3.3vw;
  margin-top: 5vw;
}
.home__svg1 {
  height: 9vw;
}
.home__div7 {
  color: #f2d729;
  text-decoration: none;
  text-align: center;
  position: relative;
  top: -6.9vw;
  font-size: 1.3vw;
  font-weight: 500;
  line-height: 1.5vw;
}
.home__div7 a {
  color: #f2d729;
  text-decoration: none;
}
.home__div8 {
  margin-left: 25.5vw;
  margin-right: 8vw;
  margin-top: -19vw;
  font-size: 5vw;
  width: 60vw;
  line-height: 5vw;
}
.home__div8 span:nth-child(1) {
  font-family: "kazuoLight";
}
.home__div8 span:nth-child(2) {
  font-family: "kazuoLig";
}
.home__div8 span:nth-child(3) {
  font-family: "kazuoLight";
}
.home__div8 span:nth-child(4) {
  font-family: "kazuoItalic";
}
.home__div8 span:nth-child(5) {
  font-family: "kazuoLight";
}
.home__div9 {
  padding-top: 5vw;
  margin-left: -9vw;
}
.home__marquee {
  font-size: 12vw;
  color: #f2d729;
  padding-top: 10vw;
  font-family: "telegraf200";
  transform: rotate(-3deg);
}
.home__svg2 {
  height: 10vw;
  position: relative;
  transform: rotate(3deg);
  z-index: 1;
}
.home__div000 {
  position: absolute;
  z-index: -9999;
  width: 100%;
  margin-top: -35vw;
  margin-left: -6vw;
}
.home__seo {
  position: relative;
}
.home__marquee span:nth-child(1) {
  position: relative;
  margin-left: 5vw;
}
.home__svg3 {
  margin-left: 0.6vw;
}
.ui_Button{
  border: 3px solid #f2d729;
  border-radius: 50px;
  background: #0000;
  box-shadow: 0px 0px 20px #ceb723;
  padding: 0.6vw 2vw;
  padding-top: 1vw;
  font-size: 2.3vmin;
  text-align: center;
  width: 28vmin;
  text-transform: uppercase;
}
.ui_Button a{
  color: #f2d729;
  text-decoration: none;
}
.home__div0 {
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
.home__div11 {
  display: flex;
  margin-right: 15vw;
  margin-top: 5vw;
}
.home__div13 {
  background-color: #f2d729;
  color: #222;
  border-radius: 1.6vw;
  padding: 0.9vw;
  padding-top: 0.9vw;
  font-size: 1.3vw;
  font-weight: 600;
  align-items: center;
  text-align: center;
  width: 11vw;
  position: relative;
  left: 10vw;
}
.home__div14 {
  width: 49vw;
  line-height: 3vw;
  font-size: 2vw;
  font-family: "newKazuo";
}
.home__div14 p:nth-child(2) {
  margin-top: 2vw;
}
.home__div14 p:nth-child(3) {
  margin-top: 2vw;
}
.home__div14 span {
  position: relative;
  margin-left: 11vw;
}
.home__div15 {
  margin-left: 5vw;
}
.home__div16 {
  display: flex;
  justify-content: space-between;
}
.home__div17 {
  border: 0.01vw solid #fff;
  color: #fff;
  border-radius: 50%;
  position: relative;
  width: 1.5vw;
  height: 1.5vw;
  background-color: #fff;
  margin-top: 0vw;
}
.home__div18 {
  margin-left: 3vw;
  font-size: 7vw;
  line-height: 6.5vw;
  font-weight: 500;
}
.home__extra {
  display: flex;
  align-items: center;
}
.home__div18 p:nth-child(1) {
  font-family: "kazuoLight";
}
.home__div18 p:nth-child(2) {
  font-family: "kazuoLig";
}
.home__div20 {
  margin-top: 12vw;
  margin-right: auto;
  margin-left: auto;
}
.home__div21 {
  font-size: 2vw;
  width: 55vw;
  margin-right: auto;
  margin-left: auto;
  line-height: 2.7vw;
}

.home__div20000 {
  margin-top: 44.5vw;
  z-index: 9999;
  background-color: #222;
  position: relative;
  margin-left: -9vw;
  height: 30vw;
  z-index: 99;
}
.home__div20000::before {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -9999999;
  background: linear-gradient(to bottom, #00000026, transparent);
}
.home__div20Extra {
  position: relative;
  top: 7vw;
}
.home__div22 {
  margin-top: 2vw;
  display: flex;
  margin-left: auto;
  margin-left: 18vw;
}
.home__div23 {
  background-color: #f2d729;
  border-radius: 1.6vw;
  color: #222;
  font-size: 1.3vw;
  line-height: 1.3vw;
  font-weight: 600;
  left: 9vw;
  padding: 0.3vw;
  padding-top: 0.8vw;
  position: relative;
  text-align: center;
  width: 9vw;
  height: 2.2vw;
  padding-top: 0.9vw;
}

.home__div240 {
  width: 55vw;
  line-height: 3vw;
  font-size: 2.2vw;
  /* margin-left: -2vw; */
}
.home__div240 span {
  position: relative;
  margin-left: 10vw;
  line-height: 3vw;
}

.home__div24 {
  margin-bottom: 5vw;
  position: relative;
  margin-left: -9vw;
  margin-right: -9vw;
  color: #f2d729;
}
.home__div26 {
  font-size: 6vw;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  text-align: center;
  text-transform: uppercase;
  align-items: center;
  font-family: "kazuoLig", sans-serif;
  cursor: default;
}
#home__div26 {
  font-size: 9vw;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  text-align: center;
  text-transform: lowercase;
  align-items: center;
  font-family: "El Messiri", sans-serif;
}
.home__div26 p:nth-child(1) {
  margin-left: 0vw;
}
.home__div26 p {
  margin-left: 2vw;
}
.home__img2, .home__img3, .home__img20, .home__img30{
  height: 5vw;
  width: 5vw;
}
.home__img2 {
  margin-left: 2vw;
  margin-top: 0.5vw;
}
.home__img3 {
  margin-left: 2vw;
  margin-right: 2vw;
  margin-top: 0.5vw;
}
.home__img20 {
  margin-left: 2vw;
  margin-top: 0.5vw;
  transform: rotate(180deg);
}
.home__img30 {
  margin-left: 2vw;
  margin-right: 2vw;
  margin-top: 0.5vw;
  transform: rotate(180deg);
}
.home__div27 {
  border: 0.01vw solid #fff;
  background: #fff;
  font-size: 3vw;
  margin: 2vw 6vw 3vw;
}
.home__div28 {
  font-family: "newKazuo";
  margin-top: 5vw;
}
.home__div30 {
  margin-bottom: 3vw;
  font-size: 10vw;
}
.home__div32 {
  font-size: 8vw;
  line-height: 8vw;
}
.home__div33 {
  margin-top: 3vw;
  display: flex;
}
.home__div33 div {
  margin-left: 4vw;
}
.home__div33 div:nth-child(1) {
  margin-left: 0vw;
}
.home__div34 p:nth-child(1) {
  font-size: 2.5vw;
}
.home__div34 p:nth-child(2) {
  margin-top: 1vw;
  width: 25vw;
  font-weight: 400;
  line-height: 1.6vw;
  font-size: 1.3vw;
  text-align: justify;
}
.home__div35 p:nth-child(1) {
  font-size: 2.5vw;
}
.home__div35 p:nth-child(2) {
  margin-top: 1vw; 
  width: 21vw; 
  line-height: 1.6vw; 
  font-size: 1.3vw; 
  text-align: justify;
}
.home__div36 p:nth-child(1) {
  font-size: 2.5vw;
}
.home__div36 p:nth-child(2) {
  margin-top: 1vw; 
  width: 27.8vw; 
  line-height: 1.6vw; 
  font-size: 1.3vw;
  text-align: justify;
}

.home__img10 {
  height: 19vw;
  width: 100%;
  transform: rotate(5deg);
  transition: all 0.5s ease-in;
  -webkit-box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.99);
  -moz-box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.99);
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.99);
}
.home__img10:hover {
  transform: rotate(0deg);
}
.home__div37 {
  margin-top: 5vw;
  position: relative;
  margin-left: -9vw;
}
.home__mar {
  height: 25vw;
}
.home__mar img {
  margin-left: 3vw;
}
.home__div38 {
  margin-top: 4vw;
  margin-left: 1vw;
  font-family: "newKazuo";
}
.home__div39 {
  display: flex;
  justify-content: space-between;
  margin-right: 14vw;
  margin-left: 1vw;
  align-items: center;
}
.home__div40 {
  display: flex;
  align-items: center;
}
.home__div40 div {
  margin-left: 2vw;
  text-align: left;
}
.home__div40 div:nth-child(1) {
  margin-left: 0vw;
}
.home__div41 {
  border: 0.01vw solid #fff;
  background-color: #fff;
  width: 1vw;
  height: 1vw;
  border-radius: 50%;
  margin-top: -5vw;
}
.home__div42 {
  font-size: 6.6vw;
  font-weight: 400;
  line-height: 7vw;
  font-family: "newKazuo";
}
.home__div42 p:nth-child(2) {
  font-weight: 300 !important;
  font-family: "newKazuo";
}
.divider {
  padding-bottom: 8vw;
}

.home__div44 {
  position: absolute;
  margin-left: 1vw;
  margin-top: 15vw;
}

.res_div45{
  display: none;
}

.home__div45 {
  perspective: 2000px;
}
.home__div46 {
  width: 79vw;
  height: 34vw;
  padding: 0 7.5vw;
  margin-left: 4.5vw;
  position: relative;
  cursor: pointer;
  margin-top: -18.5vw;
  transform: rotateX(-23deg);
  background-color: #2b2b2b;
  box-shadow: 3px -19px 37px 0px rgba(15, 2, 2, 0.3);
  transition: 0.5s ease;
}
#home__div4600{
 background: linear-gradient(to top, hsla(0, 0%, 0%, 0.8) 20%, hsla(0, 0%, 0%, 0.75) 80%),
             url("../images-webp/igeeksblog.webp") no-repeat top center/cover;
}
#home__div46{
 background: linear-gradient(to top, hsla(0, 0%, 0%, 0.8) 20%, hsla(0, 0%, 0%, 0.75) 80%),
             url("../images-webp/igeeksenglish.webp") no-repeat center center/cover;
}
#home__div460{
 background: linear-gradient(to top, hsla(0, 0%, 0%, 0.8) 20%, hsla(0, 0%, 0%, 0.75) 80%),
             url("../images-webp/geekified.jpg") no-repeat center center/cover;
}
.home__div500 p {
  margin-top: 0.5vw;
  font-size: 1.8vw;
  letter-spacing: 0.03vw;
  font-weight: 500;
}
.home__div500 a {
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 0.8vw;
}
.home__cardsExtra {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.home__div47 {
  font-size: 7vw;
  padding-top: 7.3vw;
  color: #f2d729;
}
.home__div48 {
  margin-top: 2.5vw;
  display: flex;
  justify-content: space-between;
  font-size: 1.2vw;
  margin-left: 1vw;
  color: #222;
}
.colorY {
  color: #f2d729 !important;
}
.colorY p{
  color: #f2d729 !important;
}
.colorY svg{
  color: #f2d729 !important;
}
.home__div48 a {
  color: #fff;
  margin-top: 2px;
}
.home__div48 a svg {
  color: #fff;
  font-size: 1.5vw;
}
.home__div48 p {
  color: #fff;
  font-size: 1.2vw;
}
.home__div49 {
  display: flex;
  gap: 2vw;
}
.home__div50 {
  width: 30vw;
  line-height: 1.75vw;
}
/* .home__div50:nth-child(2) {
  width: 38vw;
} */
#home__div50{
    width: 38vw;
}
#home__div51 {
  width: 41vw;
}
.home__div50 > p {
  font-size: 1.4vw;
}
.home__extra67 {
  margin-top: 2vw;
  position: relative;
  left: -2.7vw;
  line-height: 2.1vw;
}
.home__extra67 a > p{
  font-size: 1.2em !important;
  text-decoration: underline;
  border-radius: 20px;
  margin: 0 2vw;
  padding: 0.1vw 0;
  padding-top: 0.1vw;
  border: 2px solid #f2d729;
  transition: all 0.3s ease-in-out;
}
.home__extra67 a{
  color: #f2d729;
}

.home__extra67 a:hover p{
  background: #f2d729;
  color: #222;
  transition: all 0.3s ease-in-out;
}
.home__extra67 p:nth-child(1) {
  font-size: 1.8vw;
}
.home__extra67 p:nth-child(2) {
  font-size: 1.4vw;
}
.home__div51 {
  margin-left: 1.4vw;
}
.home__div52 {
  display: flex;
  flex-flow: column;
  text-align: center;
  position: relative;
}
.home__div52Extraaa {
  margin-top: 3vw;
}
.home__div53 {
  top: 15.5vw;
  position: sticky;
  align-self: flex-start;
  justify-content: space-between;
  z-index: -1;
}
.home__div53 p:nth-child(1) {
  font-size: 1.4vw;
}
.home__div54 {
  font-size: 5vw;
  margin-top: 2vw;
}
.home__div54 p:nth-child(1) {
  font-size: 2.5vw;
}
.home_ppp {
  width: 20vw;
  margin-top: 3vw;
  line-height: 1.4vw;
  font-size: 1vw;
}
.home__div55 {
  margin-left: 20vw;
}
.home__div55Extra {
  display: flex;
  gap: 4vw;
  margin-top: 3vw;
}
.home__div55Extra:nth-child(1) {
  display: flex;
  margin-top: 5vw;
}
.founder::before{
  content: "";
  background: url("../images/linkedinlogo.png") no-repeat center center/cover;
  height: 30px;
  width: 30px;
  z-index: 5;
  position: absolute;
  bottom: 7vw;
  right: 0.6vw;
}
.founder::after{
  content: "";
    height: 26vw;
    width: 25vw;
    position: absolute;
    right: 0vw;
    top: 0;
    background: linear-gradient(311deg, #000000d1, #0000 50%);
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0.5vw;
}
.home__div57Extra {
  margin-top: 2vw;
}
.home__div57Extra p {
  margin-top: 0.5vw;
  text-transform: capitalize;
  font-size: 1vw;
}
.our_team h1 {
  margin-left: 2vw;
  font-size: 3vw;
}
.our_team a {
  color: #fff;
}
.home__img40 {
  height: 27vw;
  width: 25vw;
  margin-left: -4vw;
}
.home__img400 {
  height: 24.5vw;
  margin-left: -6vw;
}
.home__img4000 {
  height: 24.5vw;
  margin-left: -6vw;
}
.home__div59 {
  text-align: justify;
    width: 28.6vw;
    font-size: 1.5vw; 
  margin-top: 1vw;
  line-height: 1.9vw;
}
.ourFound2{
  margin-left: -4vw;
  margin-right: 4vw;
  font-size: 1.58vw;
}
.home__div59 p:nth-child(2) {
  margin-top: 3vw;
}
.home__div60 {
  background-color: #222;
  color: #fff;
  margin-left: -5vw;
  padding-left: 5vw;
}
.svg__container {
  margin-top: 10vw;
}
.svg__container h1 {
  text-align: center;
  margin-left: -8vw;
  font-family: "newKazuo";
  font-size: 6vw;
  font-weight: 500;
}

.home__SvgCompo {
  height: 100vw;
  margin-top: 8vw;
}
.home__div620Extra {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3vw;
}
.home__div620Extra div {
  margin-left: 8vw;
}

#home__div63 img{
  transition: all 0.5s ease-in;
}

#home__div63 img:hover{
  transform: scale(1.3);
  transition: all 0.4s ease-in;
}

.home__div620Extra div:nth-child() {
  margin-left: 0vw;
}
.home__div62Extra {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5vw;
  width: 100vw;
  margin-left: -7vw;
}

.home__div62Extra div {
  margin-left: 3vw;
}
.home__div63:nth-child(5),
.home__div63:nth-child(10),
.home__div63:nth-child(15),
.home__div63:nth-child(20) {
  margin-left: 4vw;
}
.home__brand1 {
  height: 10vw;
  width: auto;
}

.home__setchsvg1 {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dash 5s linear alternate infinite;
}

.ourStory {
  padding-top: 15vw;
  overflow-x: hidden;
}
.ourStory__div1 > h1 {
  text-align: center;
  font-size: 6vw;
  margin-top: 8vw;
}

.SvgItem {
  stroke: #222;
}

.SvgItem path {
  stroke-width: 3;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.svg1 {
  fill: transparent;
  stroke-dasharray: 3300;
  stroke-dashoffset: 3300;
}
.svgAnimate1 {
  animation: animate1 4s linear forwards;
}

@keyframes animate1 {
  0% {
    stroke-dashoffset: 3300;
  }
  50% {
    stroke-dashoffset: 1650;
    fill: #0000;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #222;
  }
}
.svg2 {
  fill: transparent;
  stroke-dasharray: 4600;
  stroke-dashoffset: 4600;
}
.svgAnimate2 {
  animation: animate2 4s linear forwards;
}

@keyframes animate2 {
  0% {
    stroke-dashoffset: 4600;
  }
  50% {
    stroke-dashoffset: 2300;
    fill: #0000;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #222;
  }
}
.svg3 {
  fill: transparent;
  stroke-dasharray: 4400;
  stroke-dashoffset: 4400;
}
.svgAnimate3 {
  animation: animate3 4s linear forwards;
}

@keyframes animate3 {
  0% {
    stroke-dashoffset: 4400;
  }
  50% {
    stroke-dashoffset: 2200;
    fill: #0000;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #222;
  }
}
.svg4 {
  fill: transparent;
  stroke-dasharray: 6800;
  stroke-dashoffset: 6800;
}
.svgAnimate4 {
  animation: animate4 4s linear forwards;
}

@keyframes animate4 {
  0% {
    stroke-dashoffset: 6800;
  }
  50% {
    stroke-dashoffset: 3400;
    fill: #0000;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #222;
  }
}
.svg5 {
  fill: transparent;
  stroke-dasharray: 8520;
  stroke-dashoffset: 8520;
}
.svgAnimate5 {
  animation: animate5 4s linear forwards;
}

@keyframes animate5 {
  0% {
    stroke-dashoffset: 8520;
  }
  50% {
    stroke-dashoffset: 4260;
    fill: #0000;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #222;
  }
}
.svgLine1 {
  fill: transparent;
  stroke-dasharray: 3300;
  stroke-dashoffset: 3300;
}
.svgLineAnimate1 {
  animation: animate6 4s linear forwards;
}

@keyframes animate6 {
  0% {
    stroke-dashoffset: 3300;
  }
  50% {
    stroke-dashoffset: 1650;
    fill: #0000;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #222;
  }
}
.svgLine2 {
  fill: transparent;
  stroke-dasharray: 4100;
  stroke-dashoffset: 4100;
}
.svgLineAnimate2 {
  animation: animate7 4s linear forwards;
}

@keyframes animate7 {
  0% {
    stroke-dashoffset: 4100;
  }
  50% {
    stroke-dashoffset: 2050;
    fill: #0000;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #222;
  }
}
.svgLine3 {
  fill: transparent;
  stroke-dasharray: 4000;
  stroke-dashoffset: 4000;
}
.svgLineAnimate3 {
  animation: animate8 4s linear forwards;
}

@keyframes animate8 {
  0% {
    stroke-dashoffset: 4000;
  }
  50% {
    stroke-dashoffset: 2000;
    fill: #0000;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #222;
  }
}
.svgLine4 {
  fill: transparent;
  stroke-dasharray: 4100;
  stroke-dashoffset: 4100;
}
.svgLineAnimate4 {
  animation: animate9 4s linear forwards;
}

@keyframes animate9 {
  0% {
    stroke-dashoffset: 4100;
  }
  50% {
    stroke-dashoffset: 2050;
    fill: #0000;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #222;
  }
}

#svg1 {
  height: 48vw;
  width: 126vw;
  position: relative;
  left: -36vw;
}
#svgLine1 {
  position: relative;
  height: 34vw;
  left: -48vw;
  top: -45.5vw;
  width: 190vw;
}
#svg2 {
  height: 25vw;
  width: 82vw;
  position: relative;
  left: 35vw;
  top: -46.8vw;
}
#svgLine2 {
  position: relative;
  height: 43vw;
  left: -40.6vw;
  top: -74.2vw;
  width: 196vw;
}
#svg3 {
  height: 26vw;
  width: 82vw;
  position: relative;
  left: -15.4vw;
  top: -76.4vw;
}
#svgLine3 {
  position: relative;
  height: 44vw;
  left: 0vw;
  top: -114vw;
  width: 100vw;
}
#svg4 {
  height: 23vw;
  width: 83vw;
  position: relative;
  left: 36vw;
  top: -115vw;
}
#svgLine4 {
  position: relative;
  height: 49vw;
  left: 9vw;
  top: -153vw;
  width: 100vw;
}
#svg5 {
  height: 26vw;
  width: 138vw;
  position: relative;
  left: -38.6vw;
  top: -154.2vw;
}
.ourStory__div1 {
  color: #222;
}
.svgText1 {
  position: relative;
  top: -32vw;
  width: 40vw;
  text-align: center;
  left: 43vw;
  font-size: 1.7vw;
  line-height: 2vw;
}
.svgText2 {
  position: relative;
  top: -66vw;
  width: 44vw;
  margin-left: 9vw;
  text-align: center;
  left: 8vw;
  font-size: 1.7vw;
  line-height: 2vw;
}
.svgText3 {
  position: relative;
  top: -93vw;
  width: 40vw;
  text-align: center;
  left: 43vw;
  font-size: 1.7vw;
  line-height: 2vw;
}
.svgText4 {
  position: relative;
  top: -131vw;
  width: 45vw;
  margin-left: 4vw;
  text-align: center;
  left: 12vw;
  font-size: 1.7vw;
  line-height: 2vw;
}

.ourStory__div1 {
  position: relative;
  margin-left: -5.5vw;
  height: 205vw;
  transition: 0.6s all ease;
  z-index: -20000;
}

@media screen and (max-width: 1200px){
  .home__div39 {
    margin-left: 2vw;
}
}

@media screen and (max-width: 1000px){
  .founder::before{
    content: "";
    background: url("../images/linkedinlogo.png") no-repeat center center/cover;
    height: 22px;
    width: 22px;
    z-index: 5;
    position: absolute;
    bottom: 7vw;
    right: 0.6vw;
  }
}

@media screen and (max-width: 800px){
  .home__div5 {
    margin-left: 0vw;
    margin-top: 10vw;
}
  .ui_Button {
    border-radius: 40px;
    padding: 0.8vw 1vw;
    padding-top: 0.8vw;
    font-size: 1.9vmin;
    width: 20vmin;
}
.home__div8 {
  font-size: 5.5vw;
  line-height: 5.5vw;
  margin-top: -10vw;
  margin-left: 16.5vw;
}
.home__marquee {
  padding-top: 10vw;
}
.home__div34 p:nth-child(2) {
  line-height: 1.8vw;
  font-size: 1.5vw;
}
.home__div35 p:nth-child(2) {
  line-height: 1.8vw;
  font-size: 1.5vw;
}
.home__div36 p:nth-child(2) {
  line-height: 1.8vw;
  font-size: 1.5vw;
}
.home__div48 p {
  font-size: 1.3vw;
  line-height: 1.5vw;
}
.home__div59 {
  width: 30vw;
  font-size: 1.6vw;
  line-height: 2vw;
}
.home__div620Extra div {
  margin-left: 8vw;
  margin-top: 3vw;
}
.home__div63:nth-child(5), .home__div63:nth-child(10), .home__div63:nth-child(15), .home__div63:nth-child(20) {
  margin-left: 8vw;
}
.home__brand1 {
  height: 14vw;
}
}

@media screen and (max-width: 550px) {
  body {
    overflow-x: hidden !important;
  }
  .home__div1 {
    top: 60vw;
  }
  .home__div4 {
    z-index: -11111;
    position: relative;
  }
  .home__div5 {
    margin-left: 0vw;
    margin-top: -1vw;
    display: none;
  }

  .home__div8 {
    margin-left: 1.5vw;
    margin-right: 8vw;
    /* margin-top: 8vw; */
    font-size: 10.9vw;
    width: 88vw;
    line-height: 10.3vw;
    position: relative;
  }
  .home__div9 {
    margin-top: 30vw;
  }
  .home__marquee {
    z-index: -99999 !important;
    padding-top: 20vw;
    font-size: 28vw;
    font-weight: 500;
  }
  .home__marquee > p {
    position: absolute;
  }
  .home__svg3 {
    margin-left: 0.1vw;
    height: 1vw;
  }
  .home__div11 {
    margin-right: 20vw;
    margin-top: 18vw;
    margin-bottom: 10vw;
  }
  .home__div13 p {
    display: flex;
    align-items: center;
    text-align: center;
  }
  .home__div13 {
    padding: 1.5vw;
    font-size: 2.5vw;
    width: 15vw;
    left: 1vw;
    display: none;
  }
  .home__div14 {
    width: 86vw;
    font-size: 5vw;
    line-height: 6.5vw;
    margin-left: 2vw;
  }

  .home__div14 span {
    margin-left: 0vw;
  }
  .home__div15 {
    margin-top: 10vw;
    margin-right: 15vw;
    margin-left: 3vw;
  }
  .home__div20 {
    margin-left: -20vw;
  }
  .home__div21 {
    font-size: 4.5vw;
    width: 82vw;
    margin-left: 14vw;
    line-height: 5.1vw;
  }

  .home__div20000 {
    margin-top: 208vw;
    height: 43vw;
  }
  .home__div20Extra {
    top: 9vw;
  }
  .home__div23 {
    display: none;
  }

  .home__div240 {
    font-size: 4.5vw;
    width: 77vw;
    margin-left: -1.5vw;
    line-height: 5.1vw;
    margin-top: 4vw;
  }
  .home__div240 span {
    margin-left: 0vw;
    line-height: 0vw;
  }
  .home__div26 {
    font-size: 14vw;
    font-family: "kazuoLig", sans-serif;
  }
  .home__img2 {
    height: 3vw;
    margin-left: 2vw;
    margin-top: 0.5vw;
  }
  .home__img3 {
    height: 3vw;
    margin-left: 2vw;
    margin-right: 2vw;
    margin-top: 0.5vw;
  }
  .home__img20 {
    height: 3vw;
  }
  .home__img30 {
    height: 3vw;
  }
  .home__div27 {
    margin-bottom: 3vw;
    font-size: 3vw;
    margin-top: 2vw;
  }
  .home__div28 {
    margin-top: 10vw;
  }
  .home__div32 {
    font-size: 9.2vw;
    line-height: 9.3vw;
  }
  .home__div33 {
    margin-top: 5vw;
    display: block;
    margin-left: 1vw;
  }
  .home__div33 div {
    margin-left: 0vw;
  }
  .home__div34 {
    margin-top: 8vw;
  }
  .home__div34 p:nth-child(1) {
    font-size: 4.3vw;
  }
  .home__div34 p:nth-child(2) {
    margin-top: 3.5vw;
    width: 75vw;
    line-height: 4vw;
    font-size: 4vw;
  }
  .home__div35 {
    margin-top: 6vw;
  }
  .home__div35 p:nth-child(1) {
    font-size: 4.3vw;
  }
  .home__div35 p:nth-child(2) {
    margin-top: 3.5vw;
    width: 75vw;
    font-weight: 400;
    line-height: 4vw;
    font-size: 4vw;
  }
  .home__div36 {
    margin-top: 7vw;
  }
  .home__div36 p:nth-child(1) {
    font-size: 4.3vw;
  }
  .home__div36 p:nth-child(2) {
    margin-top: 3.5vw;
    width: 75vw;
    font-weight: 400;
    line-height: 4vw;
    font-size: 4vw;
  }

  .home__img10 {
    height: 45vw;
  }
  .home__div37 {
    margin-top: 8vw;
    position: relative;
    margin-left: -9vw;
    height: 30vw;
    margin-bottom: 40vw;
  }
  .home__mar {
    height: 62vw;
  }
  .home__mar img {
    margin-left: 12vw;
  }
  .home__div38 {
    margin-top: 12vw;
  }
  .home__div39 {
    margin-right: 12vw;
  }
  .home__div42 {
    font-size: 11vw;
    line-height: 10.5vw;
  }
  .home__div42 p:nth-child(2) {
    margin-left: 1.5vw;
  }
  .home__div43 {
    display: none;
  }
  .home__div45 {
    display: none;
  }
  .home__div44 {
    margin-top: 0vw;
  }
  .res_div45{
    display: flex;
    flex-flow: column;
    gap: 1.5rem;
  }
  .card{
    width: 88vw;
    background: rgb(3, 3, 3);
    padding: 0 2rem;
    padding-bottom: 1.5rem;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.99);
    -moz-box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.99);
    box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.318);
  }
  .card h1{
    color: #f2d729;
    font-size: 2em;
    padding-top: 2.5rem;
  }
  .card > p{
    margin-top: 15px;
    font-size: 1em;
    line-height: 1.1em;
  }
  .social_icons{
    margin-top: 2rem;
    display: flex;
    flex-flow: column;
    gap: 1rem;
  }
  .social_icons a{
    display: flex;
    align-items: center;
    gap: 1rem;
    text-decoration: none;
  }
  .social_icons a > svg, .social_icons a > p{
    color: #fff;
  }
  #card1{
    background: linear-gradient(to top, #000c 20%, #000000da 80%), url("../images/igeeksBlog.png") no-repeat top center/cover;
  }
  #card2{
    background: linear-gradient(to top, #000c 20%, #000000da 80%), url("../images/igeeksEnglish.png") no-repeat top center/cover;
  }
  #card3{
    background: linear-gradient(to top, #000c 20%, #000000da 80%), url("../images/igeeksHindi.png") no-repeat top center/cover;
  }
  .home__div46 {
    width: 89vw;
    height: 47vw;
    padding: 3.5vw;
    margin-left: 0vw;
    position: relative;
    cursor: pointer;
    margin-top: -18.5vw;

    transform: rotateX(-23deg);
    background-color: #2b2b2b;

    box-shadow: 3px -19px 37px 0px rgba(15, 2, 2, 0.3);
    transition: 0.5s ease;
  }

  .home__div500{
    display: flex;
  }
  .home__div500 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0.1vw;
    width: 38vw;
}

  .home__div500 p {
    display: block !important;
    margin-top: 0.5vw;
    font-size: 1.8vw;
    letter-spacing: 0.03vw;
    font-weight: 500;
  }
  .home__div48 a svg {
    font-size: 3.6vw;
  }
  .home__div48 a span{
    font-size: 2.7vw;
  }
  .home__cardsExtra {
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
  .home__div47 {
    font-size: 11vw;
    color: #f2d729;
    margin-bottom: 5vw;
  }
  .home__div48 {
    margin-top: 3vw;
    display: flex;
    justify-content: space-between;
    font-size: 1.2vw;
    margin-left: 1vw;
    color: #fff;
  }
  .home__div48 a {
    color: #fff;
  }
  .home__div48 p {
    color: #fff;
    font-size: 3vw;
    line-height: 3vw;
    display: none;
  }
  .home__div49 {
    display: flex;
    gap: 4vw;
}
  .home__div50 {
    width: 75vw;
    font-size: 18.4vw;
    line-height: 2.3vw;
  }
  .home__extra67 {
    left: -11vw;
    line-height: 5vw;
  }
  .home__extra67 p:nth-child(1) {
    font-size: 5.5vw;
  }
  .home__extra67 p:nth-child(2) {
    font-size: 4vw;
  }
  .home__extra67 a > p {
    margin: 0 25vw;
    font-size: 12px !important;
    text-decoration: none;
}
.home__extra67 a{
  text-decoration: none;
}
  .home__div51 {
    margin-top: 26vw;
    margin-left: 1.4vw;
  }
  .home__div55 {
    margin-left: 10vw;
  }
  .home__div55Extra {
    flex-direction: column-reverse;
    gap: 5vw;
    margin-top: 8vw;
  }
  .home__div55Extra:nth-child(1) {
    display: block;
  }
  .home__div55 div {
    margin-left: 0vw;
  }
  .home__div55 div:nth-child(1) {
    margin-left: 0vw;
  }
  .founder::after {
    content: "";
    height: 60vw;
    width: 53vw;
    position: absolute;
    right: 0;
    left: 6vw;
    top: -1vw;
    background: linear-gradient(311deg, #000000d1, #0000 50%);
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0.5vw;
}
.founder::before {
  content: "";
  background: url("../images/linkedinlogo.png") no-repeat center center/cover;
  height: 28px;
    width: 28px;
    z-index: 5;
    position: absolute;
    /* top: 0; */
    /* left: 0; */
    bottom: 14vw;
    right: 30vw;
}
  .our_team h1 {
    margin-left: -23vw;
    font-size: 4vw;
  }
  .home__img40 {
    height: 60vw;
    width: 53vw;
    margin-left: -22vw;
  }
  .home__div59 {
    text-align: start;
    width: 65vw;
    font-size: 3.7vw;
    margin-top: 5vw;
    line-height: 4.3vw;
  }
  .home__div59 p:nth-child(2) {
    margin-top: 3vw;
  }

  .svg__container {
    margin-top: 15vw;
  }
  .svg__container h1 {
    margin-left: -12vw;
    font-size: 8vw;
    font-weight: 500;
  }

  .home__div620Extra {
    margin-top: 2vw;
  }
  .home__div620Extra div {
    margin-left: 8vw;
  }

  .home__div620Extra div:nth-child() {
    margin-left: 0vw;
  }
  .home__div62Extra {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12vw;
  }

  .home__div63:nth-child(5),
  .home__div63:nth-child(10),
  .home__div63:nth-child(15),
  .home__div63:nth-child(20) {
    margin-left: 8vw;
  }
  .home__brand1 {
    height: 20vw;
    margin: 1vw 0;
  }

  #svg1 {
    height: 78vw;
    width: 126vw;
    position: relative;
    left: -42vw;
  }
  #svgLine1 {
    position: relative;
    height: 36vw;
    left: -47vw;
    top: -64.4vw;
    width: 190vw;
  }
  #svg2 {
    height: 39vw;
    width: 82vw;
    position: relative;
    left: 36vw;
    top: -67.8vw;
  }
  #svgLine2 {
    position: relative;
    height: 46vw;
    left: -40.8vw;
    top: -105.5vw;
    width: 196vw;
  }
  #svg3 {
    height: 40vw;
    width: 82vw;
    position: relative;
    left: -19.4vw;
    top: -109.4vw;
  }
  #svgLine3 {
    position: relative;
    height: 44vw;
    left: 0vw;
    top: -162vw;
    width: 100vw;
  }
  #svg4 {
    height: 36vw;
    width: 83vw;
    position: relative;
    left: 36vw;
    top: -164vw;
  }
  #svgLine4 {
    position: relative;
    height: 49vw;
    left: 9vw;
    top: -219.6vw;
    width: 100vw;
  }
  #svg5 {
    height: 38vw;
    width: 138vw;
    position: relative;
    left: -37.8vw;
    top: -222.2vw;
  }
  .ourStory {
    color: #222;
  }
  .svgText1 {
    position: relative;
    top: -53vw;
    width: 56vw;
    text-align: center;
    left: 38vw;
    font-size: 2.9vw;
    line-height: 2.9vw;
    color: #222;
  }
  .svgText2 {
    position: relative;
    top: -93vw;
    width: 52vw;
    margin-left: 9vw;
    text-align: center;
    left: -1vw;
    font-size: 3vw;
    line-height: 3vw;
  }
  .svgText3 {
    position: relative;
    top: -138vw;
    width: 50vw;
    text-align: center;
    left: 42vw;
    font-size: 3vw;
    line-height: 3vw;
  }
  .svgText4 {
    position: relative;
    top: -193vw;
    width: 52vw;
    margin-left: 4vw;
    text-align: center;
    left: 4vw;
    font-size: 3vw;
    line-height: 3vw;
  }

  .ourStory__div1 {
    position: relative;
    margin-left: -5.5vw;
    height: 270vw;
    transition: 0.6s all ease;
    margin-top: 18vw;
  }
}

@media screen and  (max-width: 540px){
  .home__div20000 {
    margin-top: 71.5rem;
}
}
@media screen and  (max-width: 460px){
  .home__div20000 {
    margin-top: 73rem;
  }
}
@media screen and  (max-width: 426px){
  .home__div20000 {
    margin-top: 74.5rem;
}
}
@media screen and  (max-width: 400px){
  .home__div20000 {
    margin-top: 75.5rem;
  }
}
@media screen and  (max-width: 370px){
  .home__div20000 {
    margin-top: 76rem;
  }
}
@media screen and  (max-width: 365px){
  .home__div20000 {
    margin-top: 78rem;
  }
}