.policy__div1 {
  position: relative;
  top: 30vw;
  left: 5vw;
  right: 5vw;
  margin-bottom: -10vw;
}
.policy_title p {
  margin-left: 7vw;
  margin-top: 8vw;
}
.policy_container {
  margin-top: 10vw;
  margin-left: 10vw;
  padding-right: 15vw;
}
.policy_container > h1 {
  font-size: 2.3vw;
  font-weight: 400;
}
.policy_container > p {
  font-size: 1.2vw;
  line-height: 1.5vw;
}
.policy_container > h3 {
  font-size: 1.5vw;
  color: #f2d729;
}

@media screen and (max-width: 800px){
  .policy_title p {
    margin-top: 12vw;
}
  .policy_container>h1 {
    font-size: 3vw;
}
.policy_container>p {
  font-size: 1.8vw;
  line-height: 2vw;
}
.policy_container>h3 {
  font-size: 2vw;
  line-height: 2vw;
}
}

@media screen and (max-width: 550px) {
  .policy__div1 {
    top: 45vw;
}
.policy_title {
  margin-top: 6vw;
  margin-left: 8vw;
}
  .policy_title p {
    margin-left: 7vw;
    margin-top: 8vw;
  }
  .policy_container {
    margin-top: 40vw;
    margin-left: 5vw;
    padding-right: 18vw;
  }
  .policy_container > h1 {
    font-size: 5vw;
    font-weight: 400;
    line-height: 6vw;
  }
  .policy_container > p {
    font-size: 3.5vw;
    line-height: 4vw;
  }
  .policy_container > h3 {
    font-size: 4.5vw;
    margin-top: 7vw;
    color: #f2d729;
    line-height: 5vw;
  }
}
