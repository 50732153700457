.Carousel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slideshow {
  width: 18vw;
  perspective: 1000px;
  position: relative;
  margin-top: 5vw;
  margin-bottom: 5vw;
  margin-left: -6vw;
}

.slide {
  width: 100%;
  float: right;
  animation: rotation 60s infinite linear;
  transform-style: preserve-3d;
}

.slide .slider {
  width: 25vw;
  height: 14vw;
  position: absolute;
  transform: scale(1.5);
  -webkit-box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.99);
  -moz-box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.99);
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.99);
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.slide .slider:nth-child(1) {
  transform: rotateY(90deg) translateZ(16vw);
  background: #2b2b2b;
}
.slide .slider:nth-child(2) {
  transform: rotateY(180deg) translateZ(16vw);
  background: #f2d729;
}
.slide .slider:nth-child(3) {
  transform: rotateY(270deg) translateZ(16vw);
  background: #2b2b2b;
}
.slide .slider:nth-child(4) {
  transform: rotateY(360deg) translateZ(16vw);
  background: #f2d729;
}
/* .slide .slider:nth-child(5) {
  transform: rotateY(225deg) translateZ(28vw);
  background: #2b2b2b;
}
.slide .slider:nth-child(6) {
  transform: rotateY(270deg) translateZ(28vw);
  background: #f2d729;
}
.slide .slider:nth-child(7) {
  transform: rotateY(315deg) translateZ(28vw);
  background: #2b2b2b;
}
.slide .slider:nth-child(8) {
  transform: rotateY(360deg) translateZ(28vw);
  background: #f2d729;
  color: #2b2b2b;
} */

.slider_content {
  height: 100%;
  width: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  flex-flow: column;
  padding: 0 1vw;
  text-align: center;
  color: #fff;
  filter: none;
}

.slider_conten:nth-child(1){
  color: #2b2b2b;
}

.slide:hover {
  animation-play-state: paused;
}

.slider_content h1 {
  font-size: 1vw;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}
.slider_content p {
  font-size: 0.67vw;
  font-weight: 400;
  line-height: 0.8vw;
  -webkit-font-smoothing: antialiased;
}
.slider_content a {
  font-size: 0.9vw;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}
.slider_content a:nth-child(odd) {
  color: #2b2b2b;
}

#slider_1,
#slider_3,
#slider_5,
#slider_7 {
  color: #fff;
}
#slider_2,
#slider_4,
#slider_6,
#slider_8 {
  color: #2b2b2b;
}
#slider_1 a,
#slider_3 a,
#slider_5 a,
#slider_7 a {
  color: #fff;
}

@keyframes rotation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

/* RESPONSIVE */

@media screen and (max-width: 1100px) {
  .slide .slider {
    height: 15vw;
  }

  .slide .slider:nth-child(1) {
    transform: rotateY(90deg) translateZ(22vw);
  }
  .slide .slider:nth-child(2) {
    transform: rotateY(180deg) translateZ(22vw);
  }
  .slide .slider:nth-child(3) {
    transform: rotateY(270deg) translateZ(22vw);
  }
  .slide .slider:nth-child(4) {
    transform: rotateY(360deg) translateZ(22vw);
  }
  /* .slide .slider:nth-child(5) {
    transform: rotateY(225deg) translateZ(30vw);
  }
  .slide .slider:nth-child(6) {
    transform: rotateY(270deg) translateZ(30vw);
  }
  .slide .slider:nth-child(7) {
    transform: rotateY(315deg) translateZ(30vw);
  }
  .slide .slider:nth-child(8) {
    transform: rotateY(360deg) translateZ(30vw);
  } */
  .slider_content h1 {
    font-size: 1.5vw;
  }
  .slider_content p {
    font-size: 0.9vw;
    line-height: 1.13vw;
  }
  .slider_content a {
    font-size: 0.95vw;
  }
}
@media screen and (max-width: 900px) {
  .slide .slider:nth-child(1) {
    transform: rotateY(90deg) translateZ(25vw);
  }
  .slide .slider:nth-child(2) {
    transform: rotateY(180deg) translateZ(25vw);
  }
  .slide .slider:nth-child(3) {
    transform: rotateY(270deg) translateZ(25vw);
  }
  .slide .slider:nth-child(4) {
    transform: rotateY(360deg) translateZ(25vw);
  }
  /* .slide .slider:nth-child(5) {
    transform: rotateY(225deg) translateZ(40vw);
  }
  .slide .slider:nth-child(6) {
    transform: rotateY(270deg) translateZ(40vw);
  }
  .slide .slider:nth-child(7) {
    transform: rotateY(315deg) translateZ(40vw);
  }
  .slide .slider:nth-child(8) {
    transform: rotateY(360deg) translateZ(40vw);
  } */
  .slideshow {
    margin-top: 12vw;
    margin-bottom: 20vw;
    margin-left: -4vw;
  }
  .slide .slider {
    height: 25vw;
    width: 35vw;
  }
  .slider_content h1 {
    font-size: 1.7vw;
  }
  .slider_content p {
    font-size: 1vw;
  }
  .slider_content a {
    font-size: 1.2vw;
  }
}
@media screen and (max-width: 900px) {
  .slider_content h1 {
    font-size: 2vw;
  }
  .slider_content p {
    font-size: 1.3vw;
    line-height: 1.5vw;
  }
  .slider_content a {
    font-size: 1.5vw;
  }
}
@media screen and (max-width: 650px) {
  .slideshow {
    margin-top: 12vw;
    margin-bottom: 30vw;
  }
  .slide .slider {
    height: 25vw;
    width: 40vw;
  }
  .slider_content {
    gap: 2vw;
  }
  .slider_content h1 {
    font-size: 2.5vw;
  }
  .slider_content p {
    font-size: 1.7vw;
    line-height: 1.8vw;
  }
  .slider_content a {
    font-size: 1.9vw;
  }
  .slide .slider:nth-child(1) {
    transform: rotateY(90deg) translateZ(35vw);
  }
  .slide .slider:nth-child(2) {
    transform: rotateY(180deg) translateZ(35vw);
  }
  .slide .slider:nth-child(3) {
    transform: rotateY(270deg) translateZ(35vw);
  }
  .slide .slider:nth-child(4) {
    transform: rotateY(360deg) translateZ(35vw);
  }
  /* .slide .slider:nth-child(5) {
    transform: rotateY(225deg) translateZ(55vw);
  }
  .slide .slider:nth-child(6) {
    transform: rotateY(270deg) translateZ(55vw);
  }
  .slide .slider:nth-child(7) {
    transform: rotateY(315deg) translateZ(55vw);
  }
  .slide .slider:nth-child(8) {
    transform: rotateY(360deg) translateZ(55vw);
  } */
}
@media screen and (max-width: 550px) {
  .slideshow {
    margin-top: 15vw;
    margin-bottom: 48vw;
    margin-left: -5vw;
  }
  .slide .slider {
    height: 35vw;
    width: 50vw;
  }
  .slider_content {
    gap: 3vw;
  }
  .slider_content h1 {
    font-size: 3.5vw;
  }
  .slider_content p {
    font-size: 2.3vw;
    line-height: 2.4vw;
  }
  .slider_content a {
    font-size: 2.5vw;
  }
  .slide .slider:nth-child(1) {
    transform: rotateY(90deg) translateZ(35vw);
  }
  .slide .slider:nth-child(2) {
    transform: rotateY(180deg) translateZ(35vw);
  }
  .slide .slider:nth-child(3) {
    transform: rotateY(270deg) translateZ(35vw);
  }
  .slide .slider:nth-child(4) {
    transform: rotateY(360deg) translateZ(35vw);
  }
  /* .slide .slider:nth-child(5) {
    transform: rotateY(225deg) translateZ(60vw);
  }
  .slide .slider:nth-child(6) {
    transform: rotateY(270deg) translateZ(60vw);
  }
  .slide .slider:nth-child(7) {
    transform: rotateY(315deg) translateZ(60vw);
  }
  .slide .slider:nth-child(8) {
    transform: rotateY(360deg) translateZ(60vw);
  } */
}
