.contact__div1 {
  position: relative;
  top: 30vw;
  left: 5vw;
  right: 5vw;
  margin-bottom: -10vw;
}
.contact {
  margin-top: 10vw;
  margin-left: 5vw;
  padding-right: 15vw;
  text-align: center;
}
.contact_div1 h1 {
  font-size: 3vw;
  color: #f2d729;
}
.contact_div1 > div {
  margin-top: 3vw;
  line-height: 1.5vw;
}
.contact_div1 p {
  font-size: 1.5vw;
  margin: 0.5vw 0;
}
.contact_div2 {
  margin: 8vw 0;
}
.contact_div2 h1 {
  font-size: 1.5vw;
}
.contact_div2 h2 {
  font-size: 2.5vw;
  font-weight: 200;
  margin-top: 3vw;
  margin-left: 2vw;
  margin-bottom: 3vw;
}
.contact_div2 h2 a {
  color: #f2d729;
  text-decoration: none;
}
.contact_div2 p {
  font-size: 2.5vw;
  margin-top: 3vw;
  margin-left: 2vw;
  margin-bottom: 3vw;
  color: #f2d729;
}
.contact_div3 h1 {
  font-size: 1.5vw;
}
.google_map {
  height: 30vw;
  width: 65vw;
  margin-top: 3vw;
}

@media screen and (max-width: 550px) {
  .contact {
    margin-top: 40vw;
    margin-left: 0vw;
    margin-bottom: -30vw;
    padding-right: 15vw;
  }
  .contact_div1 h1 {
    font-size: 5.5vw;
    color: #f2d729;
  }
  .contact_div1 > div {
    margin-top: 3vw;
    margin-left: 0vw;
  }
  .contact_div1 p {
    font-size: 3.5vw;
    line-height: 3.2vw;
    margin: 0.5vw 0;
  }
  .contact_div2 {
    margin: 8vw 0;
  }
  .contact_div2 h1 {
    font-size: 4vw;
  }
  .contact_div2 h2 {
    font-size: 4.5vw;
    margin-top: 5vw;
    margin-left: 2vw;
    margin-bottom: 8vw;
  }
  .contact_div2 p {
    font-size: 4vw;
    margin-top: 5vw;
    margin-left: 2vw;
    margin-bottom: 3vw;
    color: #f2d729;
  }
  .contact_div3 h1 {
    font-size: 4vw;
  }
  .google_map {
    height: 50vw;
    width: 70vw;
    margin-top: 3vw;
  }
}
