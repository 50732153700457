.carrer__div1 {
  position: relative;
  top: 30vw;
  left: 5vw;
  right: 5vw;
  margin-bottom: -10vw;
}

.carrer__div8 {
  position: relative;
  left: 5vw;
  top: 5vw;
  margin-left: 21.5vw;
  margin-right: 8vw;
  margin-top: -19vw;
  font-size: 5vw;
  width: 60vw;
  line-height: 6vw;
}

.arrer__extra1 {
  margin-top: 10vw;
}
.arrer__extra10 {
  margin-top: 5vw;
}
.carrer__div10 {
  display: flex;
  justify-content: space-between;
  margin-top: 3.5vw;
}

.carrer__div9 {
  border: 0.01vw solid #fff;
  width: 88vw;
}
.carrer__div90 {
  border: 0.01vw solid #fff;
  width: 88vw;
  margin-top: 7vw;
}
.carrer__div11 {
  margin-left: 3vw;
}
.carrer__div12 {
  font-size: 1.1vw;
  margin-left: 0.8vw;
}

.carrer__div13 {
  font-size: 5.3vw;
  line-height: 5vw;
  width: 20vw;
  margin-top: 2.6vw;
}
.carrer__div15 {
  font-size: 1.1vw;
}

.carrer__div16 {
  width: 50vw;
  font-size: 1.6vw;
  line-height: 2.2vw;
  margin-top: 3vw;
}

.carrer__div8000 {
  position: relative;
  left: 5vw;
  top: 5vw;
  margin-left: 21.5vw;
  margin-right: 8vw;
  margin-top: -19vw;
  font-size: 9vw;
  width: 60vw;
  line-height: 8vw;
}

.career_motion {
  position: relative;
  left: 5vw;
  top: 5vw;
  margin-left: 15.5vw;
  margin-right: 8vw;
  margin-top: -19vw;
  font-size: 7.7vw;
  width: 65vw;
  line-height: 8vw;
}

.apply_Button{
  border: 3px solid #f2d729;
  border-radius: 50px;
  background: #f2d729;
  box-shadow: 0px 0px 20px #ceb723;
  padding: 0.6vw 2vw;
  padding-top: 1vw;
  font-size: 2.3vmin;
  text-align: center;
  width: 28vmin;
  text-transform: capitalize;
  margin-top: 1.5vw;
}
.apply_Button a{
  color: #222;
  text-decoration: none;
}

.staff__div2 {
  margin-top: 5vw;
  display: flex;
  margin-right: 5vw;
}

.staff__div3 > a {
  color: #f2d729;
  font-size: 2.5em;
  line-height: 2.5em;
  text-transform: uppercase;
}

.staff__div3 {
  margin-left: 3vw;
  font-size: 1.3vw;
  width: 20vw;
}
.staff__div3 p:nth-child(1) {
  color: #ccc;
  margin-bottom: 1vw;
  margin-left: 3rem;
  font-size: 1.1em;
}
.staff__div3 p:nth-child(2) {
  font-size: 2.5vw;
  margin-top: 1vw;
}
.staff__div7 {
  width: 49vw;
}
.staff__div4 {
  margin-left: 13.3vw;
}
.staff__div4 > p {
  font-size: 2.3vw;
}
.staff__div5 {
  margin-top: 1.8rem;
}
.staff__div50 {
  margin-top: 4vw;
}
.staff__div6 {
  font-size: 1.3vw;
  color: #ccc;
}
.staff__div7 {
  margin-top: 1vw;
  font-size: 1.1vw;
  margin-left: 1vw;
}

ul li {
  margin-top: 1vw;
  line-height: 1.4vw;
}

@media screen and (max-width: 1200px){
  .staff__div3 p:nth-child(1) {
    margin-left: 3.2rem;
}
}
@media screen and (max-width: 1200px){
  .staff__div3 p:nth-child(1) {
    margin-left: 3.5rem;
}
}
@media screen and (max-width: 550px){
  .staff__div3 p:nth-child(1) {
    margin-left: 0rem;
}
}

@media screen and (max-width: 550px) {
  .carrer__div1 {
    position: relative;
    top: 40vw;
    left: 5vw;
    right: 5vw;
  }
  .carrer__div12 > p, .staff__div6 > p{
    font-size: 1.7em;
  }

  .carrer__div8 {
    position: relative;
    left: 5vw;
    top: 33vw;
    margin-left: 6vw;
    margin-right: 8vw;
    margin-top: -19vw;
    font-size: 11vw;
    width: 60vw;
    line-height: 10vw;
  }

  .arrer__extra1 {
    margin-top: 70vw;
  }
  .arrer__extra10 {
    margin-top: 7vw;
  }
  .carrer__div10 {
    display: block;
    justify-content: space-between;
    margin-top: 6vw;
  }

  .carrer__div9 {
    border: 0.01vw solid #fff;
    width: 88vw;
  }
  .carrer__div90 {
    border: 0.01vw solid #fff;
    width: 88vw;
    margin-top: 7vw;
  }
  .carrer__div11 {
    margin-left: 3vw;
  }
  .carrer__div12 {
    font-size: 2.7vw;
    margin-left: 0.8vw;
  }

  .carrer__div13 {
    font-size: 9vw;
    line-height: 9vw;
    width: 70vw;
    margin-top: 7vw;
  }
  .carrer__div14 {
    margin-left: 4vw;
  }
  .carrer__div15 {
    font-size: 1.1vw;
    display: none;
  }

  .carrer__div16 {
    width: 75vw;
    font-size: 3.8vw;
    line-height: 4.5vw;
    margin-top: 3vw;
  }

  .carrer__div8000 {
    position: relative;
    left: 5vw;
    top: 5vw;
    margin-left: 18.5vw;
    margin-right: 8vw;
    margin-top: 9vw;
    font-size: 13vw;
    width: 60vw;
    line-height: 13vw;
  }
  .staff__div1 {
    margin-top: -30vw;
  }
  .staff__div2 {
    margin-top: 5vw;
    display: block;
    margin-right: 5vw;
    margin-left: 1vw;
  }

  .staff__div3 {
    margin-left: 3vw;
    font-size: 2.3vw;
    width: 40vw;
  }
  .staff__div3 p:nth-child(1) {
    color: #ccc;
    font-size: 2em;
    line-height: 2em;
  }
  .staff__div3 p:nth-child(2) {
    font-size: 5.5vw;
    margin-top: 4vw;
  }
  .staff__div7 {
    width: 75vw;
  }
  .staff__div4 {
    margin-left: 3vw;
    margin-top: 8vw;
  }
  .staff__div4 > p {
    font-size: 1.5em;
  }
  .staff__div5 {
    margin-top: 0.8vw;
  }
  .staff__div50 {
    margin-top: 4vw;
  }
  .staff__div6 {
    font-size: 3vw;
    margin-top: 8vw;
    color: #ccc;
  }
  .staff__div7 {
    margin-top: 4vw;
    font-size: 3.4vw;
    margin-left: 1vw;
    line-height: 4.7vw;
  }

  ul li {
    margin-top: 1vw;
    line-height: 5.4vw;
  }
}
