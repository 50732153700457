@import url("https://fonts.googleapis.com/css2?family=Arvo&display=swap");

@font-face {
  font-family: "newKazuo";
  src: url("./fonts/newKazuo.woff2");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-display: swap;
}

body {
  background-color: #222;
  color: #fff;
  font-family: "newKazuo";
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 0vw;
}
