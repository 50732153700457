.blob__holder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

body {
  transition: 1.5s all ease;
  overflow-x: hidden;
}

body.loading {
  overflow: hidden;
  height: 100vh;
}

html{
  overflow-x: hidden;
}

@media only screen and (max-width: 1024px) {
  .blob__holder {
    height: 70vh;
  }
}
@media only screen and (max-width: 800px) {
  .blob__holder {
    height: 60vh;
    margin-top: 5vw;
  }
}
@media only screen and (max-width: 500px) {
  .blob__holder {
    height: 50vh;
    margin-top: 10vw;
  }
}
