.Footer {
  margin-top: 50vw;
  width: 100%;
  background: #222;
}
.footer_Container1 {
  height: 15rem;
  width: 100%;
  border-top: 1px solid #f2d729;
  border-bottom: 1px solid #f2d729;
  display: flex;
}
.footer_Container2 {
  width: 100%;
}

/* Footer Container 1 */

.footer_div1 {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 25vw;
  padding: 2.5rem;
  padding-right: 4vw;
}
.footer_div2 {
  display: flex;
  flex-flow: column;
  width: 15vw;
  border-left: 1px solid #f2d729;
  border-right: 1px solid #f2d729;
  background: transparent;
}
.footer_div3 {
  display: flex;

  width: 60vw;
  position: relative;
}

#social__icons {
  padding-top: 10px;
}
#social__icons span {
  padding: 1vw;
}
#social__icons span a {
  color: #f2d729;
}

/* Footer_Div_1 */

.div1_text > h3 {
  font-weight: 300;
  font-size: 22px;
  color: #f2d729;
  line-height: 1.8vw;
}

/* Footer_Div_2 */

.linkedin {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  flex: 1;
  height: 50%;
  border-bottom: 1px solid #f2d729;
  transition: 0.3s all ease-in-out 0s;
  color: #f2d729;
  font-weight: 400;
  font-size: 1.2vw;
  line-height: 1.5vw;
}

.linkedin > span {
  position: absolute;
  height: 0%;
  width: 100%;
  left: 0;
  bottom: 0;
  transition: 0.3s all ease-in-out 0s;
  background-color: #f2d729;
}

.linkedin:hover .to_top {
  height: 100%;
  transition: 0.3s all ease-in-out 0s;
}

.linkedin > a {
  color: #f2d729;
  z-index: 2;
  text-decoration: none;
  text-align: center;
}
.linkedin:hover > a {
  color: #222;

}

.careers {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  flex: 1;
  height: 50%;
  transition: 0.3s all ease-in-out 0s;
  color: #f2d729;
  font-weight: 400;
  font-size: 1.2vw;
  line-height: 1.5vw;
}

.careers:hover {
  color: #222222;
}

.careers > span {
  position: absolute;
  height: 0%;
  width: 100%;
  left: 0;
  top: 0;
  transition: 0.3s all ease-in-out 0s;
  background-color: #f2d729;
}

.careers:hover .to_bottom {
  height: 100%;
  transition: 0.3s all ease-in-out 0s;
}

.careers > a {
  color: #f2d729;
  z-index: 2;
  text-decoration: none;
}
.careers:hover > a {
  color: #222;
}

/* Footer_Div_3 */

/* Footer_Container_2 */

.footer__lower {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;
}
.footer__lower > h3 {
  font-weight: 400;
  color: #f2d729;
}
.footer__lower > h3:nth-child(1) {
  margin-left: 2vw;
}
.footer__lower > h3 a {
  margin-right: 2vw;
}

/* RESPONSIVE */

@media screen and (max-width: 1000px) {
  .footer_div1 {
    padding: 1.5rem;
  }
  .footer_Container1 {
    height: 12rem;
  }
  .div1_text > h3 {
    font-size: 18px;
    line-height: 1.5rem;
  }
}

@media screen and (max-width: 900px) {
  .linkedin > a {
    font-size: 12px;
    line-height: 15px;
  }
  .careers > a {
    font-size: 12px;
    line-height: 15px;
  }
  #social__icons span {
    padding: 0.5vw;
  }
  #social__icons span a {
    font-size: 1vw;
  }
}
@media screen and (max-width: 700px) {
  .footer_Container1 {
    height: 15rem;
    display: flex;
    flex-wrap: wrap;
  }
  .footer_div1 {
    width: 70%;
    padding: 2.5rem;
  }
  .footer_div2 {
    width: 30%;
  }
  .footer_div3 {
    width: 100%;
    border-top: 1px solid #f2d729;
  }
  #footer_marquee {
    font-size: 7.5vw;
    padding: 2vw 0;
  }
  .footer__lower > h3:nth-child(1) {
    font-size: 2.2vw;
  }
  .footer__lower > h3 a {
    margin-right: 2vw;
    font-size: 2.2vw;
    line-height: 2.4vw;
  }
  #social__icons span {
    padding: 1vw;
  }
  #social__icons span a {
    font-size: 1vw;
    line-height: 1.2vw;
  }
}

@media screen and (max-width: 550px) {
  .Footer{
    margin-top: 75vw;
  }
  .footer_div1 {
    padding: 1rem;
  }
  .footer__lower > h3:nth-child(1) {
    font-size: 3vw;
    line-height: 3.2vw;
  }
  .footer__lower > h3 a {
    margin-right: 2vw;
    font-size: 3vw;
    line-height: 3.2vw;
  }
}
