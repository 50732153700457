@font-face {
  font-family: "kazuoDisplayLight";
  src: url("../fonts/kazuoDisplayLight.woff2");
}
@font-face {
  font-family: "kazuoRegular";
  src: url("../fonts/kazuo.woff2");
}
@font-face {
  font-family: "kazuoLight";
  src: url("../fonts/kazuoLight.woff2");
}

.navbar__div1 {
  margin-top: 5vw;
  margin-left: 5vw;
  margin-right: 5vw;
}
.navbar__div2 {
  position: fixed;
  z-index: 999;
}
.navbar__div3 {
  display: flex;
  justify-content: space-between;
}
.navbar__img1 {
  height: 5vw;
  transition: 0.4s ease;
  z-index: 999;
}
.navbar__lgoSvg {
  width: 33vmin;
  transition: 0.6s ease;
  z-index: 99999;
}
.nav__logoSvg2 {
  width: 0vmin;
  transition: 0.6s ease;
  z-index: 99999;
  fill: #222;
  position: absolute;
  top: 0;
  height: 9.6vmin;
}
#logo_div2{
  position: absolute;
  top: 0;
  left: -42%;
  width: 10vmin;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 9.6vmin;
}
.navbar__div6 {
  position: fixed;
  display: block;
  left: 87vw;
  z-index: 99999;
}
.navbar__div7 {
  display: flex;
  align-items: center;
  z-index: 9999999;
  transition: 0.7s ease;
}
.navbar__div8 {
  color: #f2d729;
  font-family: "kazuoLight";
  font-size: 1vw;
  margin-top: -0.5vw;
  transition: 0.4s ease;
}
.navbar__div80 {
  color: #f2d729;
  font-family: "kazuoLight";
  font-size: 1vw;
  margin-top: -0.5vw;
  transition: 0.4s ease;
  position: absolute;
  opacity: 0;
}
.navbar__div9 {
  margin-left: 1.5vw;
  border: 0.13vw solid #f2d729;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar__div4 {
  background-color: #f2d729;
  color: #222;
  height: 20vmax;
  width: 91vw;
  transition: 0.4s ease;
  z-index: 999;
}

.navbar__div5 {
  position: relative;
  left: 2.2vw;
}
.navbar__div5 p:nth-child(1) {
  font-family: "kazuoDisplayLight";
  font-weight: 500;
  font-size: 3.5vw;
}
.navbar__div5 p:nth-child(2) {
  font-family: "kazuoRegular";
  font-weight: 600;
  font-size: 3vw;
}

.hamburger_close {
  display: none;
  z-index: 99999;
  border: 0.125vw solid #222;
  border-radius: 50%;
  margin-left: 1.3vw;
}
.ham__svg1 {
  height: 4.5vw;
  width: 4.5vw;
}

.ham__div1 {
  margin-left: 2.7vw;
  display: none;
}
.ham__div2 {
  display: flex;
}
.ham__div3 {
  margin-top: 48vh;
  font-size: 1.6vw;
  line-height: 1.8vw;
}
.ham__div3 > p {
  margin-top: 3vw;
}
.ham__extra1 > p a{
  text-decoration: none;
  color: #222;
}
.ham__div4 {
  margin-left: 12vw;
  font-size: 5vw;
  font-weight: 500;
  line-height: 6vw;
  margin-top: 3.5vh;
}

.ham__div5 a{
  text-decoration: none;
  margin-bottom: 2vw;
}
.ham__div5{
  transition: all 0.5s ease-in;
}

.ham__div5:hover{
  margin-left: 2vw;
  transition: all 0.5s ease-in;
  transform: scale(1.05);
}
.ham__div5::before{
  content: "\2192";
  position: absolute;
  left: -5vw;
  opacity: 0;
  font-size: 4vw;
  transition: all 0.3s ease-in;
}

.ham__div5:hover::before{
  opacity: 1;
  transition: all 0.3s ease-in;
}

.social__icons {
  padding-top: 1vw;
}
.social__icons span {
  padding: 1vw;
}
.social__icons span a {
  color: #222;
}
.ham__span1 {
  height: 3vw;
}


/* ERROR PAGE CSS */

.wrapper{
  margin-bottom: -45vw;
}
.error_page{
  position: relative;
  top: 32vw;
  left: 5vw;
}

.redirect{
  position: relative;
  margin-top: 50vw;
  text-align: center;
}

.redirect h1{
  font-size: 1.8vw;
}
.redirect a{
  color: #f2d729;
}

@media screen and (max-width: 1300px){
  #logo_div2 {
    left: -40%;
}
}
@media screen and (max-width: 1200px){
  #logo_div2 {
    left: -37%;
}
}
@media screen and (max-width: 1100px){
  #logo_div2 {
    left: -35%;
}
}
@media screen and (max-width: 900px){
  #logo_div2 {
    left: -30%;
}
}
@media screen and (max-width: 800px){
  .ham__div4 {
    bottom: 24vh;
    font-size: 6vw;
    font-weight: 500;
    line-height: 8.5vw;
    margin-left: 17vw;
    margin-top: 3.5vh;
    position: absolute;
    left: 22vw;
}
.ham__div3 {
  font-size: 2.6vw;
  line-height: 3vw;
  margin-top: 48vh;
}
.navbar__div8, .navbar__div80 {
  font-size: 1.6vw;
}

.redirect h1{
  font-size: 2.2vw;
}
}
@media screen and (max-width: 700px){
  #logo_div2 {
    left: -25%;
}
}
@media screen and (max-width: 650px){
  .redirect{
    margin-top: 60vw;
}
.redirect h1{
    font-size: 3vw;
}
.eror{
    top: 20vw;
}
.ham__div4 {
  margin-top: 10.5vh;
  top: 10vw;
  left: 18vw;
}
}
@media screen and (max-width: 500px){
  #logo_div2 {
    left: -36%;
}
}

@media screen and (max-width: 550px) {
  .navbar__div1 {
    margin-top: 7vw;
    margin-left: 5vw;
    margin-right: 5vw;
  }
  .navbar__div2 {
    position: fixed;
    z-index: 999;
  }
  .navbar__div3 {
    display: flex;
    justify-content: space-between;
  }

  .navbar__div4 {
    background-color: #f2d729;
    color: #222;
    height: 20vmax;
    width: 91vw;
    transition: 0.4s ease;
    z-index: 999;
  }

  .navbar__div5 {
    position: relative;
    left: 2.2vw;
    margin-top: 3vw;
  }
  .navbar__div5 p:nth-child(1) {
    font-family: "kazuoDisplayLight";
    font-weight: 500;
    font-size: 3.5vw;
  }
  .navbar__div5 p:nth-child(2) {
    font-family: "kazuoRegular";
    font-weight: 600;
    font-size: 3vw;
  }
  .ham__svg1 {
    height: 12vw;
    width: 12vw;
  }

  .hamburger_close {
    display: none;
    z-index: 99999;
    margin-left: 1.3vw;
  }
  .navbar__lgoSvg {
    width: 33vmin;
    transition: 0.4s ease;
    z-index: 99999;
  }
  .navbar__div6 {
    position: fixed;
    display: block;
    left: 72vw;
    z-index: 99999;
  }
  .navbar__div7 {
    display: flex;
    align-items: center;
    z-index: 9999999;
    transition: 0.7s ease;
  }
  .navbar__div8 {
    color: #f2d729;
    font-family: "kazuoLight";
    font-size: 3vw;
    margin-top: -0.5vw;
    transition: 0.4s ease;
  }
  .navbar__div80 {
    color: #f2d729;
    font-family: "kazuoLight";
    font-size: 3vw;

    transition: 0.4s ease;
    position: absolute;
    opacity: 0;
  }
  .navbar__div9 {
    margin-left: 2.5vw;
  }

  .nav__logoSvg2 {
    width: 0vmin;
    transition: 0.6s ease;
    z-index: 99999;
    fill: #222;
}

  .ham__div1 {
    margin-top: 35vw;
    margin-left: 4.7vw;
    display: none;
  }
  .ham__div2 {
    display: flex;
    flex-direction: column-reverse;
  }
  .ham__div3 {
    margin-top: 45vh;
    font-size: 4.5vw;
    margin-left: 9.7vw;
    line-height: 5.3vw;
    /* z-index: -1; */
  }
  .ham__div3 > p {
    margin-top: 3vw;
  }
  .ham__extra1 {
    margin-bottom: 0.3vw;
  }
  .ham__div4 {
    top: 18vh;
    bottom: 0;
    font-size: 10vw;
    font-weight: 500;
    line-height: 7.5vw;
    margin-left: -11vw;
    margin-top: 3.5vh;
    position: absolute;
    left: 24vw;
    /* z-index: -1; */
    height: 20vh;
}

.ham__div5 {
  margin-top: 5vw;
}

  .social__icons {
    padding-top: 10px;
  }
  .social__icons span {
    padding: 1vw;
  }
  .social__icons span a {
    color: #222;
  }

  .eror {
    top: 0vw;
    left: 0vw;
}
.error_page {
  position: relative;
  top: 35vw;
  left: -2vw;
}
.redirect {
  margin-top: 80vw;
}
.redirect h1 {
font-size: 4vw;
}
}
