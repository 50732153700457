.our__div1 {
  position: relative;
  top: 30vw;
  left: 5vw;
  right: 5vw;
  margin-bottom: -10vw;
}
.team__div1 {
  margin-top: 9vw;
  margin-left: 5vw;
}
.team__div3 {
  font-size: 3.3vw;
    line-height: 4vw;
    width: 81vw;
}
.team__img1 {
  height: 28vw;
}
.team__div4 {
  margin-top: 5vw;
}
.team__div6 {
  margin-left: 17vw;
}
#team__div14 {
  transition: all 0.3s ease-in;
}
.team__div60 {
  margin-top: 10vw;
  margin-left: 5vw;
}
.team__div7 p {
  font-size: 0.8vw;
  text-transform: uppercase;
  padding-top: 1vw;
  margin-left: 3.3vw;
  color: #f2d729;
}
.team__div8 {
  display: flex;
  margin-top: 1vw;
}
.team__div8 > a{
  z-index: 5;
}
.team__div9::before{
  content: "";
  background: url("../images/linkedinlogo.png") no-repeat center center/cover;
  height: 2vw;
  width: 2vw;
  z-index: 5;
  position: absolute;
  bottom: 1vw;
  left: 25vw;
}
#anoop::before{
  content: "";
  background: url("../images/Twitterlogo.png") no-repeat center center/cover;
}
.team__div9::after{
  content: "";
  height: 28vw;
  width: 27vw;
  position: absolute;
  top: -1vw;
  background: linear-gradient(311deg, #000000d1, #0000 50%);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0.5vw;
}
.team__div10 {
  margin-left: -2.5vw;
  margin-top: 5vw;
  z-index: 6;
  height: 5vw;
}
.team__div11 {
  font-size: 3.7vw;
  line-height: 3.5vw;
  margin-left: 3vw;
}
.team__div12 {
  margin-top: 2vw;
}
.team__div13 {
  border: 0.01vw solid #fff;
  border-radius: 50%;
  height: 5vw;
  width: 5.1vw;
}

.team__icon1 {
  font-size: 2.3vw !important;
  margin-left: 1.3vw;
  margin-top: 1.2vw;
  transition: 0.6s ease;
}
.team__icon10 {
  font-size: 2.3vw !important;
  margin-left: 0vw;
  margin-top: 1.1vw;
  transition: 0.6s ease;
  position: absolute;
  left: 52.7vw;
}
.team__icon100 {
  font-size: 2.3vw !important;
  margin-left: 0vw;
  margin-top: 1.2vw;
  transition: 0.6s ease;
  position: absolute;
  left: 26.9vw;
}
.team__div14 {
  width: 24vw;
  font-weight: 500;
  line-height: 1.5vw;
  font-size: 1vw;
  margin-top: 18vw;
  display: none;
  transition: 0.6s ease;
  position: absolute;
  margin-left: 33vw;
  text-align: justify;
}
.team__div140 {
  margin-top: 0.5vw;
  transition: 0.6s ease;
}

.right1 {
  margin-left: 17vw;
    margin-top: 11vw;
}

.right10 {
  margin-left: -2.5vw;
  margin-top: 5vw;
}

.right12 {
  margin-top: 2vw;
  margin-left: 0vw;
}

.right100 {
  margin-left: 0vw;
  position: absolute;
  left: 26.9vw;
}

@media screen and (max-width: 550px) {
  .t19{
    margin-bottom: 80vw;
  }
  #our_team {
    margin-left: 18vw;
    font-size: 10vw;
  }

  .our__div1 {
    position: relative;
    /* top: 30vw; */
    top: 55vw;
    left: 5vw;
    right: 5vw;
  }
  .team__div1 {
    margin-top: 75vw;
    margin-left: 3vw;
  }
  .team__div3 {
    font-size: 8vw;
    width: 80vw;
    line-height: 8vw;
  }

  .team__img1 {
    height: 75vw;
    width: 76vw;
  }
  .team__div4 {
    margin-top: 10vw;
  }
  .team__div6 {
    margin-left: 3vw;
  }
  .team__div60 {
    margin-top: 15vw;
    margin-left: 3vw;
  }
  .team__div7{
    position: absolute;
    margin-top: 5vw;
  }

  .team__div7 p {
    font-size: 3vw;
    padding-top: 3vw;
    margin-left: 0vw;
  }
  .team__div8 {
    display: block;
    margin-top: 1.1vw;
  }
  .team__div9::before{
  content: "";
  background: url("../images/linkedinlogo.png") no-repeat center center/cover;
  height: 7vw;
  width: 7vw;
  z-index: 5;
  position: absolute;
  bottom: 0;
  top: 66vw;
  left: 66.5vw;
}
  .team__div9::after {
    height: 75vw;
    width: 75vw;
}
  .team__div10 {
    margin-left: 0vw;
    margin-top: 4vw;
    display: flex;
    justify-content: space-between;
  }
  .team__div10 div {
    margin-right: 8.8vw;
  }
  .team__div10 div:nth-child(1) {
    margin-left: 0vw;
    display: flex;
    gap: 5px;
  }
  .team__div11 {
    font-size: 5.7vw;
    line-height: 5.5vw;
  }
  .team__div12 {
    margin-top: -1vw;
  }
  .team__div13 {
    border: 0.01vw solid #fff;
    border-radius: 50%;
    height: 9vw;
    width: 9.1vw;
  }

  .team__icon1 {
    font-size: 5vw !important;
    margin-left: 2vw;
    margin-top: 1.6vw;
  }
  .team__icon10 {
    font-size: 5vw !important;
    margin-left: 0vw;
    margin-top: 1.5vw;
    transition: 0.6s ease;
    position: absolute;
    left: 75.5vw;
  }
  .team__icon100 {
    font-size: 5vw !important;
    margin-left: 0vw;
    margin-top: 1.7vw;
    position: absolute;
    left: 69.6vw;
  }
  .team__div14 {
    width: 77vw;
    font-weight: 500;
    /* font-size: 3vw;
    line-height: 3.8vw; */
    font-size: 0.85em;
    line-height: 1.3em;
    margin-top: 7vw;
    /* display: none; */
    position: relative;
    margin-left: 0vw;
    transition: 1s all ease;
  }
  .team__div140 {
    margin-top: 0.5vw;
    transition: 0.6s ease;
  }
}
